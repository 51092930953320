<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">代理商管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">协议列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 5px">
            <div title="机构名称" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 6rem">机构名称:</span>
              <el-input
                v-model="agreementComp"
                type="text"
                size="small"
                clearable
                placeholder="请输入机构名称"
              />
            </div>
            <div title="申请状态" class="searchboxItem ci-full">
              <span class="itemLabel">申请状态:</span>
              <el-select
                clearable
                size="small"
                v-model="agreementState"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in agreementStateData"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div class="btnBox">
              <el-button class="bgc-bv" round @click="getData()"
                >查询</el-button
              >
              <el-button class="bgc-bv" round @click="handleCreate('add')"
                >新增</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                width="100px"
                :index="indexMethod"
              />
              <el-table-column
                label="协议类型"
                align="left"
                prop="agreementTypeName"
                show-overflow-tooltip
              />
              <el-table-column
                label="签订机构"
                align="left"
                prop="agreementComp"
                show-overflow-tooltip
              />
              <el-table-column
                label="联系人"
                align="left"
                prop="partyAName"
                show-overflow-tooltip
              />
              <el-table-column
                label="联系电话"
                align="left"
                prop="partyAMobile"
                show-overflow-tooltip
              />
              <el-table-column
                label="所在区域"
                align="left"
                prop="areaName"
                show-overflow-tooltip
              />
              <el-table-column
                label="收费标准"
                align="center"
                show-overflow-tooltip
                prop="chargesSettlement"
              />
              <el-table-column
                label="申请日期"
                align="left"
                prop="createTime"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="申请状态"
                align="left"
                prop="agreementStateStr"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{ scope.row.agreementStateStr }}
                  <el-popover
                    v-if="scope.row.agreementState == '20'"
                    placement="top-start"
                    width="200"
                    trigger="hover"
                    :content="'驳回原因:' + scope.row.remarks"
                  >
                    <i slot="reference" class="el-icon-document"></i>
                  </el-popover>
                </template>
              </el-table-column>
            
              <el-table-column
                label="确认人"
                align="left"
                prop="confirmName"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{ scope.row.confirmName || "--" }}
                </template>
              </el-table-column>
                <el-table-column
                label="确认日期"
                align="left"
                prop="confirmTimeStr"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{ scope.row.confirmTimeStr || "--" }}
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                align="center"
                width="140px"
                fixed="right"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    :disabled="scope.row.agreementState == '30'"
                    @click="handleCreate('edit', scope.row.agreementId)"
                    >编辑</el-button
                  >
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    :disabled="scope.row.agreementState == '30'"
                    @click="handleDelete(scope.row.agreementId)"
                    >删除</el-button
                  >
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    :disabled="scope.row.agreementState != '30'"
                    @click="lookFile(scope.row.agreementId)"
                    >查看附件</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="70%"
      top="2%"
      @close="doCancel('ruleForm')"
    >
      <div class="ovy-a" style="height: 600px">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="120px"
          label-position="right"
          class="demo-ruleForm"
        >
          <el-form-item label="协议类型" prop="agreementType">
            <el-select
              size="small"
              v-model="ruleForm.agreementType"
              clearable
              placeholder="请选择协议类型"
            >
              <el-option
                v-for="item in agreementTypeData"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="签订机构" prop="agreementComp" class="form-item">
            <el-input v-model="ruleForm.agreementComp" size="small"></el-input>
          </el-form-item>
          <el-form-item label="联系人" prop="partyAName">
            <el-input
              v-model="ruleForm.partyAName"
              size="small"
              maxlength="30"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="partyAMobile">
            <el-input v-model="ruleForm.partyAMobile" size="small"></el-input>
          </el-form-item>
          <el-form-item label="所在区域" prop="areaId">
            <el-cascader
              v-model="ruleForm.areaId"
              placeholder="请选择所在区域"
              :options="areaTreeList"
              :props="propsArea"
              size="small"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="收费标准" prop="chargesSettlement">
            <el-input
              v-model="ruleForm.chargesSettlement"
              size="small"
              type="textarea"
              show-word-limit
              maxlength="255"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>

      <div style="display: flex; justify-content: center; padding-top: 17px">
        <el-button @click="doCancel('ruleForm')" class="bgc-bv">取消</el-button>
        <el-button @click="getSure('ruleForm')" class="bgc-bv">确定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="预览"
      :visible.sync="dialogCert"
      width="50%"
      top="2%"
      center
      :before-close="closeDownloadCert"
    >
      <div id="pdf-cert" style="height: 600px"></div>
    </el-dialog>
    <el-dialog title="查看附件" :visible.sync="filedialogVisible" width="30%">
      <el-table
        ref="multipleTable"
        :data="fileList"
        size="small"
        tooltip-effect="dark"
        style="width: 100%"
        :header-cell-style="tableHeader"
        stripe
      >
        <el-table-column label="序号" align="center" type="index" />
        <el-table-column
          label="上传人"
          align="center"
          show-overflow-tooltip
          prop="createName"
        />
        <el-table-column
          label="上传时间"
          align="center"
          show-overflow-tooltip
          prop="createTimeStr"
        />
        <el-table-column
          label="文件名称"
          align="center"
          show-overflow-tooltip
          prop="appendixName"
        />
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <div>
              <el-button
                type="text"
                size="mini"
                style="padding: 0 5px"
                @click="jumpLook(scope.row)"
                >查看附件</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
import pdf from "pdfobject";
export default {
  name: "operate/accountList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    var Phone = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入联系人电话"));
      } else if (
        value &&
        !/^1[3456789]\d{9}$/.test(value) &&
        !/^([0-9]{3,4}-)?[0-9]{7,8}$/.test(value)
      ) {
        callback(new Error("请输入正确的联系人电话"));
      } else {
        callback();
      }
    };
    return {
      /* 机构名称 */
      agreementComp: "",
      /* 申请状态 -- 数据 */
      agreementStateData: [],
      agreementState: "", //申请状态
      /* 行政区划 -- 数据 */
      areaTreeList: [],
      propsArea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      /* 申请协议表单 -- 弹框显示||隐藏 */
      dialogVisible: false,
      dialogTitle: "新增协议",
      /* 协议类型 -- 数据 */
      agreementTypeData: [],
      /* 申请协议表单 -- 数据字段 */
      ruleForm: {
        agreementType: "", //协议类型
        agreementComp: "", //签订机构
        partyAName: "", //联系人
        partyAMobile: "", //联系电话
        areaId: "", //所在区域
        chargesSettlement: "", //收费标准
      },
      /* 申请协议表单 -- 数据字段校验 */
      rules: {
        agreementType: [
          { required: true, message: "请选择协议类型", trigger: "change" },
        ],
        agreementComp: [
          { required: true, message: "请输入签订机构", trigger: "blur" },
        ],
        partyAName: [
          { required: true, message: "请输入联系人", trigger: "blur" },
        ],
        partyAMobile: [{ required: true, validator: Phone, trigger: "blur" }],
        areaId: [
          { required: true, message: "请选择所在区域", trigger: "change" },
        ],
        chargesSettlement: [
          { required: true, message: "请输入收费标准", trigger: "blur" },
        ],
      },
      dialogCert: false,
      filedialogVisible: false,
    };
  },
  computed: {},
  created() {
    this.getAreaTree();
    this.getAgreementCodeValue();
  },
  methods: {
    /* 获取行政区划 */
    getAreaTree() {
      this.$post("/sys/area/tree").then((ret) => {
        let data = ret.data || [];
        this.areaTreeList = data;
      });
    },
    /* 获取码值表 -- 协议类型 && 申请状态 */
    getAgreementCodeValue() {
      /* 协议类型 */
      const agreementType = this.$setDictionary("AGREEMENT_TYPE", "list");
      for (const key in agreementType) {
          if (key != '40') {
                 this.agreementTypeData.push({
          value: key,
          label: agreementType[key],
        });
          }
       
      }
      /* 申请状态 */
      const agreementState = this.$setDictionary("AGREEMENT_STATE", "list");
      for (const key in agreementState) {
        this.agreementStateData.push({
          value: key,
          label: agreementState[key],
        });
      }
    },

    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.agreementComp) {
        params.agreementComp = this.agreementComp;
      }
      if (this.agreementState) {
        params.agreementState = this.agreementState;
      }
      this.doFetch({
        url: "/biz/settlement/agreement/list",
        params,
        pageNum,
      });
    },
    // 修改
    handleCreate(stu, id = "") {
      this.dialogVisible = true;
      this.stu = stu;
      if (stu == "edit") {
        this.dialogTitle = "修改协议";
        this.agreementId = id;
        this.getInfo(id);
      } else {
        this.dialogTitle = "新增协议";
      }
    },
    //查看附件
    lookFile(agreementId) {
      this.$post("/biz/settlement/agreement/checkAppendix", {
        agreementId,
      }).then((ret) => {
        console.log(ret);
        this.filedialogVisible = true;
        this.fileList = ret.data || [];
      });
    },
    jumpLook(item) {
      let extension = item.appendixName.substring(
        item.appendixName.lastIndexOf(".") + 1
      );
      if (extension == "pdf" || extension == "PDF") {
        this.dialogCert = true;
        this.$nextTick(() => {
          pdf.embed(item.agreementUrl, "#pdf-cert");
        });
      }
    },
    getInfo(agreementId) {
      this.$post("/biz/settlement/agreement/info", { agreementId }).then(
        (res) => {
          this.ruleForm = {
            ...res.data,
          };
        }
      );
    },
    /* 申请协议 -- 取消 */
    doCancel(ruleForm) {
      this.ruleForm = {};
      this.$refs[ruleForm].resetFields();
      this.dialogVisible = false;
    },
    /* 申请协议 -- 确认 */
    getSure(ruleForm) {
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          let parmar = {
            ...this.ruleForm,
          };
          this.$post(
            this.stu == "add"
              ? "/biz/settlement/agreement/insert"
              : "/biz/settlement/agreement/update",
            parmar
          ).then((res) => {
            if (res.status == 0) {
              if (this.stu == "add") {
                this.$message({
                  message: "新增成功",
                  type: "success",
                });
              } else {
                this.$message({
                  message: "修改成功",
                  type: "success",
                });
              }
              this.dialogVisible = false;
              this.getData(-1);
            }
          });
        }
      });
    },
    handleDelete(agreementId) {
      this.doDel({
        url: "/biz/settlement/agreement/delete",
        msg: "确定删除该申请协议吗？",
        ps: {
          type: "post",
          data: { agreementId },
        },
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>

<style lang="less" scope>
.el-textarea__inner {
  min-height: 260px !important;
}
.el-tooltip__popper {
  max-width: 45% !important;
}
</style>
